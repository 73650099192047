import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../../layouts/Header";
import Breadcrumbs from "../../layouts/Breadcrumbs";

import { useLocation } from "react-router-dom";

function TurfCutters() {
  const location = useLocation();

  const pageTitle = "Resources for Turf Cutters | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta name="description" content="Coming soon..." />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "Resources for Turf Cutters" }} />
      <section className="client-section">
        <div className="container">
          <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
            <div className="col pt-3">
              <div className="card">
                <div className="card-body p-3">
                  <p>This page is coming soon. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default TurfCutters;
