import React from "react";

function AlertBanner({ title, message, type }) {
  // Other oranges:
  //BD7A00: dark
  //704901: darker
  //E07800
  //D4A20B
  //EB9800
  return (
    <div
      style={{
        height: window.innerWidth > 768 ? "100px" : "30vh",
        padding: window.innerWidth > 768 ? "24px 56px" : "24px",
        boxShadow: "inset 2px 2px 10px 0 rgba(0, 0, 0, .50)",
        fontFamily: "'Roboto', sans-serif",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor:
          type === "info" ? "#E07800" : type === "danger" ? "#702F07" : "white",
        color:
          type === "info" ? "white" : type === "danger" ? "white" : "black",
      }}
    >
      <p
        style={{
          fontSize: "24px",
          fontWeight: "500",
          margin: "0",
        }}
      >
        {title}
      </p>
      <p
        style={{
          fontSize: "18px",
          fontWeight: "300",
          margin: "0",
        }}
        dangerouslySetInnerHTML={{ __html: message }}
      ></p>
    </div>
  );
}

export default AlertBanner;
