import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../../layouts/Header";
import Breadcrumbs from "../../layouts/Breadcrumbs";

import { useLocation } from "react-router-dom";

function TrainingAndResources() {
  const location = useLocation();

  const pageTitle = "Training and Resources | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta name="description" content="Coming soon..." />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "Training and Resources" }} />
      <section className="client-section">
        <div className="container">
          <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
            <div className="col pt-3">
              <div className="card">
                <div className="card-body p-3">
                  <p>
                    We want to make sure you have all the training you need to
                    be a successful Neighborhood Leader! Please explore the
                    resources below.
                  </p>
                </div>
              </div>
              <br />
              <div className="card">
                <div className="card-header">
                  <h3>Training</h3>
                </div>
                <div className="card-body p-3">
                  <p>
                    For an interactive online training for the Neighborhood
                    Leader Program,{" "}
                    <a href="https://www.clackamasdems.org/nl-training/">
                      <u>please watch this short video</u>
                    </a>
                    .
                  </p>
                  <br />
                  <p>These Fact Sheets give you some quick references:</p>
                  <ul>
                    <li>
                      <a href="/assets/neighborhood-leader/fact-sheets/NL Admin and MiniVAN- Which to Use_.pdf">
                        NL Admin and MiniVAN- Which to Use?
                      </a>
                    </li>
                    <li>
                      <a href="/assets/neighborhood-leader/fact-sheets/Reading your Walk Sheet in NL Admin.pdf">
                        Reading your Walk Sheet in NL Admin
                      </a>
                    </li>
                    <li>
                      <a href="/assets/neighborhood-leader/fact-sheets/Setting Up and Navigating in MiniVAN.pdf">
                        Setting Up and Navigating in MiniVAN
                      </a>
                    </li>
                    <li>
                      <a href="/assets/neighborhood-leader/fact-sheets/Talking to your Neighbors.pdf">
                        Talking to your Neighbors
                      </a>
                    </li>
                    <li>
                      <a href="/assets/neighborhood-leader/fact-sheets/Entering Data in NL Admin.pdf">
                        Entering Data in NL Admin
                      </a>
                    </li>
                    <li>
                      <a href="/assets/neighborhood-leader/fact-sheets/Entering Data in MiniVAN.pdf">
                        Entering Data in MiniVAN
                      </a>
                    </li>
                    <li>
                      <a href="/assets/neighborhood-leader/fact-sheets/Following Up in NL Admin.pdf">
                        Following up in NL Admin
                      </a>
                    </li>
                    <li>
                      <a href="/assets/neighborhood-leader/fact-sheets/Following Up in MiniVAN.pdf">
                        Following Up in MiniVAN
                      </a>
                    </li>
                    <li>
                      <a href="/assets/neighborhood-leader/fact-sheets/Dealing with Issues.pdf">
                        Dealing with Issues
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <br />
              <div className="card">
                <div className="card-body p-3">
                  <p>
                    If you would like to participate in an in-person training,{" "}
                    <a href="https://calendar.google.com/calendar/u/0/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America/Los_Angeles&amp;src=Zm1kY2wyMWs0OWJjMTBxYTdnbG5qdTk0amNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%23743500&amp;showTabs=1&amp;showCalendars=0&amp;mode=AGENDA&amp;showDate=0)">
                      check out our calendar
                    </a>{" "}
                    to see if we have one coming up.
                  </p>
                </div>
              </div>
              <br />
              <div className="card">
                <div className="card-header"></div>
                <div className="card-body p-3">
                  <p>
                    Join our{" "}
                    <a href="http://www.facebook.com/groups/clackamasnl/">
                      Clackamas Neighborhood Leader Facebook group
                    </a>{" "}
                    to chat with other NLs!
                  </p>
                  <br />
                  <p>
                    We recommend leaving a personal note with any literature.
                    Download these files for some sample letters you can
                    customize:
                  </p>
                  <ul>
                    <li>
                      <a href="/assets/neighborhood-leader/sample-letter/Sample%20Letters.docx">
                        Sample Letters (docx)
                      </a>
                    </li>
                    <li>
                      <a href="/assets/neighborhood-leader/sample-letter/Sample%20Letters.pdf">
                        Sample Letters (pdf)
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default TrainingAndResources;
