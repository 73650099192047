import React, { Component, Fragment } from "react";
import About from "./comps/About";
import Banner from "./comps/Banner";
import LatestNewAndOpinions from "./comps/LatestNewsAndOpinions";
import ElectedOfficials from "./comps/ElectedOfficials";
import GetEndorsed from "./comps/GetEndorsed";
import Features from "./comps/Features";
import Merch from "./comps/Merch";
import NeighborhoodLeaderProgram from "./comps/NeighborhoodLeaderProgram";

class Content extends Component {
  render() {
    return (
      <Fragment>
        <Banner />
        {/* <UpcomingEvents /> */}
        <About />
        <LatestNewAndOpinions />
        <Merch />
        <NeighborhoodLeaderProgram />
        <ElectedOfficials />
        <GetEndorsed />
        <Features />
      </Fragment>
    );
  }
}

export default Content;
