import React from "react";
import { Link } from "react-router-dom";

import icon1 from "../../../../assets/img/icons/01.png";
import icon2 from "../../../../assets/img/icons/02.png";
import icon3 from "../../../../assets/img/icons/03.png";

const serviceblock = [
  {
    icon: icon1,
    title: "State Senator<br>List",
    text: "All current Oregon State Senators. Also listed are their Capitol phone numbers, and email, office and website addresses",
    link: "https://www.oregonlegislature.gov/senate/Pages/SenatorsAll.aspx",
  },
  {
    icon: icon2,
    title: "State Representative List",
    text: "All current Oregon State Representatives. Also listed are their Capitol phone numbers, and email, office and website addresses",
    link: "https://www.oregonlegislature.gov/house/Pages/RepresentativesDistrict.aspx",
  },
  {
    icon: icon3,
    title: "Elected Official Lookup",
    text: "All current Oregon State Elected Officials. Also listed are their Capitol phone numbers, and email, office and website addresses",
    link: "https://geo.maps.arcgis.com/apps/instant/lookup/index.html?appid=fd070b56c975456ea2a25f7e3f4289d1",
  },
];

function ElectedOfficials(props) {
  return (
    <section className="service-section shape-style-one section-gap grey-bg">
      <div className="container">
        {/* Section Title */}
        <div className="section-title text-center both-border mb-30">
          <span className="title-tag">Our Elected Officials</span>
          <h2 className="title">
            Oregon State Senator, Oregon State Representative, and elected
            officials serving in Washington, DC
          </h2>
        </div>
        {/* Services Boxes */}
        <div className="row service-boxes justify-content-center">
          {serviceblock.map((item, i) => (
            <div
              key={i}
              className="col-lg-4 col-md-6 col-sm-8 col-10 col-tiny-12 wow fadeInLeft"
              data-wow-duration="1500ms"
              data-wow-delay="400ms"
            >
              <div className="service-box text-center">
                {/* <div className="icon">
                                    <img src={item.icon} alt="Icon" />
                                </div> */}
                <h3>
                  <a
                    href={item.link}
                    target="_blank"
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  ></a>
                </h3>
                <p>{item.text}</p>

                <a href={item.link} target="_blank" className="service-link">
                  <i className="fal fa-long-arrow-right" />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <div className="dots-line">
                <img src={lines} alt="" />
            </div> */}
    </section>
  );
}

export default ElectedOfficials;
