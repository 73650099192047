import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../../layouts/Header";
import Breadcrumbs from "../../layouts/Breadcrumbs";

import { useLocation, Link } from "react-router-dom";

function GetHelp() {
  const location = useLocation();

  const pageTitle = "Get Help | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta name="description" content="Coming soon..." />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "Get Help" }} />
      <section className="client-section">
        <div className="container">
          <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
            <div className="col pt-3">
              <div className="card">
                <div className="card-body p-3">
                  <p>
                    Do you need some help or have some questions about the
                    Neighborhood Leader Program? Use these resources to get the
                    help you need!
                  </p>
                  <p>
                    Have a question?{" "}
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="mailto:nl@clackamasdems.org"
                    >
                      <u>Email us</u>
                    </a>{" "}
                    to send your question to our panel of experienced
                    Neighborhood Leaders.
                  </p>
                </div>
              </div>
              <br />
              <div className="card">
                <div className="card-body p-3">
                  <p>
                    For any questions specific to the Neighborhood Leader
                    Program in your House District:&nbsp; Contact your{" "}
                    <Link to="/house-district-leaders">
                      <u>House District Leader</u>
                    </Link>
                    .
                  </p>
                </div>
              </div>
              <br />
              <div className="card">
                <div className="card-body p-3">
                  <p>
                    Don’t know what House District you live in? Check{" "}
                    <a href="https://maps.clackamas.us/maps/cmap">here</a>.
                    Enter your address in the upper right-hand corner and then
                    click on “Voting.”
                  </p>
                </div>
              </div>
              <br />
              <div className="card">
                <div className="card-body p-3">
                  <p>
                    Have general questions and want to chat with other
                    Neighborhood Leaders?{" "}
                    <a href="http://www.facebook.com/groups/clackamasnl/">
                      <u>Join our Facebook group</u>
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default GetHelp;
