import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Content from "../sections/home/Content";
import AlertBanner from "../layouts/AlertBanner";
import { useLocation } from "react-router-dom";

function Home() {
  const location = useLocation();

  const pageTitle =
    "Working Together to Do Better | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Get to know the Democratic Party of Clackamas County. News, events and volunteer information. Thinking of running for office, start here."
        />
      </MetaTags>
      <Header />
      <AlertBanner
        type="info"
        title="Buy Tickets for the Yes! We Can Dinner and Auction on October 19th"
        message="<a style='color: white;' href='https://clackamasdems.partysupporters.com/ywc2024/register/ticket_sales'>Get your tickets before they sell out!</a>"
      />
      <Content />
      <Footer />
    </Fragment>
  );
}

export default Home;
