import React, { Component } from "react";
import { Link } from "react-router-dom";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="container">
          <div className="footer-widget">
            <div className="row">
              {/* <div className="col-md-6 col-sm-12 order-2">
                <div className="widget newsletter-widget">
                  <h4 className="widget-title text-center">
                    Subscribe to Our Newsletters
                  </h4>
                  <div className="newsletter-form text-center">
                    {/*<form action="#">*/}
              {/*<input type="email" placeholder="Enter Your Email" />}
                    <a
                      href="https://feedburner.google.com/fb/a/mailverify?uri=clackamasdems/dhJE&loc=en_US"
                      className="main-btn"
                      target="_blank"
                    >
                      Subscribe Now
                    </a>
                    {/*</form>}
                  </div>
                </div>
              </div> */}
              <div className="col-lg-6 col-sm-12 order-3">
                <div className="widget nav-widget">
                  <h4 className="widget-title">Quick Links</h4>
                  <ul>
                    <li>
                      <Link
                        to="/contacts-and-organization"
                        style={{ color: "white" }}
                      >
                        Contacts and Organization
                      </Link>
                    </li>
                    <li>
                      <Link to="/blog-posts" style={{ color: "white" }}>
                        Latest News &amp; Blog
                      </Link>
                    </li>
                    {/* <li>
                      <a
                        href="https://feedburner.google.com/fb/a/mailverify?uri=clackamasdems/dhJE&loc=en_US"
                        target="_blank"
                        style={{ color: "white" }}
                      >
                        Subscribe to Our Newsletters
                      </a>
                    </li> */}
                    <li>
                      <span style={{ color: "white", marginRight: "8px" }}>
                        Social Media:
                      </span>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/ClackamasDems"
                        style={{ color: "white", marginRight: "8px" }}
                      >
                        <i
                          className="fab fa-facebook"
                          style={{
                            marginRight: "8px",
                          }}
                        ></i>
                        Facebook
                      </a>
                      |
                      <a
                        target="_blank"
                        href="https://twitter.com/ClackamasDems"
                        style={{
                          color: "white",
                          marginLeft: "8px",
                          marginRight: "8px",
                        }}
                      >
                        <i
                          className="fab fa-twitter"
                          style={{
                            marginRight: "8px",
                          }}
                        ></i>
                        Twitter
                      </a>
                      |
                      <a
                        target="_blank"
                        href="https://www.instagram.com/clackamasdemocrats/?igshid=YTM0ZjI4ZDI="
                        style={{ color: "white", marginLeft: "8px" }}
                      >
                        <i
                          className="fab fa-instagram"
                          style={{
                            marginRight: "8px",
                          }}
                        ></i>
                        Instagram
                      </a>
                    </li>
                    <li>
                      <Link
                        to="/privacy-policy"
                        style={{
                          color: "white",
                        }}
                      >
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/*<div className="col-lg-4 col-sm-12 order-5">*/}
              {/*  <div className="widget nav-widget">*/}
              {/*    <h4 className="widget-title">Site Map</h4>*/}
              {/*    <ul>*/}
              {/*      <li>*/}
              {/*        <Link to="/contacts-and-organization">*/}
              {/*          Contacts and Organization*/}
              {/*        </Link>*/}
              {/*      </li>*/}
              {/*      <li>*/}
              {/*        <Link to="#">World Wide Clients</Link>*/}
              {/*      </li>*/}
              {/*      <li>*/}
              {/*        <Link to="#">Happy People’s</Link>*/}
              {/*      </li>*/}
              {/*      <li>*/}
              {/*        <Link to="#">Winning Awards</Link>*/}
              {/*      </li>*/}
              {/*      <li>*/}
              {/*        <Link to="#">Company Statics</Link>*/}
              {/*      </li>*/}
              {/*    </ul>*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div className="col-lg-6 col-sm-12 order-lg-4 order-5">
                <div className="widget contact-widget">
                  <h4 className="widget-title">Contact Us</h4>
                  {/* <p>Clackamas County Democrats</p> */}
                  <ul className="contact-infos">
                    <li>
                      <a
                        href="tel:5036561931"
                        target="_blank"
                        style={{ color: "white" }}
                      >
                        <i
                          style={{ color: "white" }}
                          className="far fa-phone"
                        />
                        (503) 656-1931
                      </a>
                    </li>
                    <li>
                      <a
                        href="mailto:outreach@clackamasdems.org"
                        target="_blank"
                        style={{ color: "white" }}
                      >
                        <i
                          style={{ color: "white" }}
                          className="far fa-envelope-open"
                        />
                        outreach@clackamasdems.org
                      </a>
                    </li>
                    <li>
                      <span
                        style={{ color: "white" }}
                        onClick={() => {
                          window.open(
                            "https://goo.gl/maps/FECdGsTct8g9zyA17",
                            "_blank"
                          );
                        }}
                      >
                        <i
                          style={{ color: "white" }}
                          className="far fa-map-marker-alt"
                        />{" "}
                        609 Main Street Oregon City, OR 97045
                      </span>
                    </li>
                    <li>
                      <i
                        style={{ color: "white" }}
                        className="far fa-calendar"
                      />
                      <a
                        style={{ color: "white" }}
                        href="https://docs.google.com/document/d/1M4vIl_HYhcMdiXKBn3qFYTb4NxTbQas59zcVyoIuR_w/edit"
                      >
                        Current Hours
                      </a>
                    </li>
                    <li>
                      <i
                        style={{ color: "white" }}
                        className="far fa-map-marker-alt"
                      />{" "}
                      <span style={{ color: "white" }}>
                        Mailing address: P.O. Box 680, Gladstone, OR 97027
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 order-lg-4 order-5">
                <p>
                  Paid for by the Clackamas County Democratic Central Committee
                  PAC, ID #293. The top donor was the Ken Humberston for
                  Clackamas County Commission PAC.
                </p>
              </div>
            </div>
          </div>
          <div className="footer-copyright">
            <p className="copyright-text">
              <span style={{ color: "white" }}>
                © Democratic Party of Clackamas County
              </span>
              <span>
                <a
                  href="https://nwappworks.com/"
                  target="_blank"
                  style={{ color: "white" }}
                >
                  Powered by Northwest Appworks
                </a>
              </span>
            </p>
            <Link to="#" className="back-to-top" onClick={scrollToTop}>
              <i className="far fa-angle-up" />
            </Link>
          </div>
        </div>
        {/* Lines */}
        {/* <img src={shape1} alt="line" className="line-one" /> */}
        {/* <img src={shape2} alt="line" className="line-two" /> */}
      </footer>
    );
  }
}

export default Footer;
