import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import videobg from "../../../../assets/img/video-bg/pexels-hoang-le-978535 (1).jpg";
import playicon from "../../../../assets/img/icons/play.svg";
import lines from "../../../../assets/img/lines/08.png";

import { articles } from "../../../../data/articles";

function LatestNewAndOpinions(props) {
  return (
    <section
      className="video-section bg-img-c section-gap"
      style={{ backgroundImage: "url(" + videobg + ")" }}
    >
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-xl-7 col-lg-8 col-md-10 order-2 order-md-1">
            <div className="video-text">
              <div className="section-title left-border mb-30">
                <span className="title-tag">News</span>
                <h2 className="title">
                  Latest News and <br /> Opinions
                </h2>
              </div>
              <div>
                {articles.slice(0, 3).map((art, idx) => {
                  return (
                    <div
                      key={idx}
                      style={{
                        marginBottom: "20px",
                        borderLeft: "4px solid #D4A20B",
                        paddingInlineStart: "20px",
                      }}
                    >
                      {art.externalLink?.length > 0 ? (
                        <a
                          href={art.externalLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <h4
                            style={{
                              color: "white",
                            }}
                          >
                            {art.title}
                          </h4>
                        </a>
                      ) : (
                        <Link to={"/blog-posts/" + art.endpoint}>
                          <h4
                            style={{
                              color: "white",
                              textDecoration: "underline",
                            }}
                          >
                            {art.title}
                          </h4>
                        </Link>
                      )}
                    </div>
                  );
                })}
              </div>
              <Link to="/blog-posts" className="main-btn">
                News
              </Link>
            </div>
          </div>
          {/* <div className="col-lg-3 col-lg-4 col-md-2 order-1 order-md-2">
                        <div className="video-btn text-md-center wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                            <span onClick={openVideoModal} className="play-btn popup-video cursor" role="button">
                                <img src={playicon} alt="" />
                                <i className="fas fa-play" />
                            </span>
                        </div>
                    </div> */}
        </div>
      </div>
      <div className="line-shape">
        <img src={lines} alt="Line" />
      </div>
    </section>
  );
}

export default LatestNewAndOpinions;
