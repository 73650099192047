import React, { Component } from "react";
import { Link } from "react-router-dom";

const navigationmenu = [
  {
    id: 1,
    linkText: "Home",
    link: "/",
  },
  {
    id: 2,
    linkText: "Events",
    link: "/events-calendar",
  },
  {
    id: 3,
    linkText: "Neighborhood Leader",
    child: true,
    submenu: [
      {
        id: 31,
        link: "/neighborhood-leader/program",
        linkText: "Neighborhood Leader Program",
      },
      {
        id: 32,
        link: "/neighborhood-leader/training-and-resources",
        linkText: "Training and Resources",
      },
      {
        id: 33,
        link: "/neighborhood-leader/resources-for-turf-cutters",
        linkText: "Resources for Turf Cutters",
      },
      {
        id: 34,
        link: "/neighborhood-leader/get-help",
        linkText: "Get Help",
      },
    ],
  },
  {
    id: 4,
    linkText: "Volunteer",
    child: true,
    submenu: [
      {
        id: 41,
        link: "/volunteer-your-time",
        linkText: "Volunteer Your Time",
      },
      {
        id: 42,
        link: "/donate",
        linkText: "Volunteer Through Donation",
      },
      {
        id: 43,
        link: "/neighborhood-leader-program",
        linkText: "Neighborhood Leader Program",
      },
      {
        id: 44,
        link: "/nl-training",
        linkText: "Neighborhood Leader Training",
        externalLink: true,
      },
      {
        id: 45,
        link: "/pcp",
        linkText: "PCP Application",
      },
      {
        id: 46,
        link: "/other-ways-to-donate",
        linkText: "Other Ways to Donate",
      },
      {
        id: 47,
        link: "/join-our-central-committee-meetings",
        linkText: "Join Our Central Committee Meetings",
      },
    ],
  },
  {
    id: 5,
    linkText: "About the DPCC",
    child: true,
    submenu: [
      {
        id: 52,
        link: "/our-mission",
        linkText: "Our Mission",
      },
      {
        id: 53,
        link: "/officers",
        linkText: "Officers",
      },
      {
        id: 54,
        link: "/house-district-leaders",
        linkText: "House District Leaders",
      },
      {
        id: 55,
        link: "/standing-committee-and-caucuses",
        linkText: "Standing Committee and Caucuses",
      },
      {
        id: 56,
        link: "/delegates",
        linkText: "Delegates",
      },
      {
        id: 57,
        link: "/dpcc-by-laws",
        linkText: "Bylaws of the Central Committee",
      },
      {
        id: 58,
        link: "/documentation",
        linkText: "Documentation",
      },
      {
        id: 59,
        link: "/platform",
        linkText: "Platform",
      },
      {
        id: 60,
        link: "/standing-rules",
        linkText: "Standing Rules",
      },
      {
        id: 61,
        link: "/parliamentary-procedure",
        linkText: "Parliamentary Procedure",
      },
      {
        id: 62,
        link: "/oregon-trail-democrats",
        linkText: "Oregon Trail Democrats",
      },
      {
        id: 63,
        link: "https://docs.google.com/document/d/1M4vIl_HYhcMdiXKBn3qFYTb4NxTbQas59zcVyoIuR_w/edit",
        linkText: "Current Hours",
        externalLink: true,
      },
    ],
  },
  {
    id: 6,
    linkText: "Blog Posts and News",
    child: true,
    submenu: [
      {
        id: 61,
        link: "/blog-posts",
        linkText: "Blog Posts",
      },
      // {
      //   id: 62,
      //   link: "/news",
      //   linkText: "News",
      // },
    ],
  },
  {
    id: 7,
    linkText: "Contact Us",
    link: "/contacts-and-organization",
  },
  {
    id: 8,
    linkText: "Candidate Portal",
    child: true,
    submenu: [
      {
        id: 81,
        linkText: "Getting Ready to Run",
        link: "/candidate-portal/getting-ready-to-run",
      },
      {
        id: 87,
        linkText: "Should you run?",
        link: "/candidate-portal/should-you-run",
      },
      {
        id: 82,
        linkText: "Candidate Communications Strategy",
        link: "/candidate-portal/candidate-communications-strategy",
      },
      {
        id: 83,
        linkText: "Candidate Training",
        link: "/candidate-portal/candidate-training",
      },
      // {
      //   id: 84,
      //   linkText: "Already Won-What Now?",
      //   link: "/candidate-portal/already-won-what-now",
      // },
      // {
      //   id: 85,
      //   linkText: "Better Luck Next Time",
      // },
      {
        id: 86,
        linkText: "Candidate Resources",
        link: "/candidate-portal/candidate-resources",
      },
    ],
  },
];

const Navmenu = () => {
  const getNextSibling = (elem, selector) => {
    // Get the next sibling element
    var sibling = elem.nextElementSibling;
    // If there's no selector, return the first sibling
    if (!selector) return sibling;
    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
      if (sibling.matches(selector)) return sibling;
      sibling = sibling.nextElementSibling;
    }
  };

  const triggerChild = (e) => {
    let subMenu = "";
    subMenu =
      getNextSibling(e.target, ".submenu") !== undefined
        ? getNextSibling(e.target, ".submenu")
        : null;
    if (subMenu !== null && subMenu !== undefined && subMenu !== "") {
      subMenu.classList = subMenu.classList.contains("d-block")
        ? "submenu"
        : "submenu d-block";
    }
  };
  return (
    <ul>
      {navigationmenu.length > 0
        ? navigationmenu.map((item, i) => (
            <li
              key={i}
              className={`${item.child ? "has-submemu" : ""} `}
              onClick={triggerChild}
            >
              {item.child ? (
                <>
                  {" "}
                  <Link onClick={(e) => e.preventDefault()} to="/">
                    {" "}
                    {item.linkText}{" "}
                  </Link>{" "}
                  <span className="dd-trigger">
                    <i className="fal fa-angle-down"></i>
                  </span>{" "}
                </>
              ) : (
                <Link to={item.link}> {item.linkText} </Link>
              )}
              {item.child ? (
                <ul className="submenu" role="menu">
                  {item.submenu.map((sub_item, i) => (
                    <li
                      key={i}
                      className={`${sub_item.child ? "has-submemu" : ""} `}
                    >
                      {sub_item.child ? (
                        <>
                          {" "}
                          <Link onClick={(e) => e.preventDefault()} to="/">
                            {" "}
                            {sub_item.linkText}{" "}
                          </Link>{" "}
                          <span className="dd-trigger">
                            <i className="fal fa-angle-down"></i>
                          </span>{" "}
                        </>
                      ) : sub_item.externalLink ? (
                        <a
                          href={sub_item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {sub_item.linkText}
                        </a>
                      ) : (
                        <Link to={sub_item.link}> {sub_item.linkText} </Link>
                      )}
                      {sub_item.submenu ? (
                        <ul className="submenu">
                          {sub_item.submenu.map((third_item, i) => (
                            <li key={i}>
                              <Link to={third_item.link}>
                                {third_item.linkText}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </li>
                  ))}
                </ul>
              ) : null}
            </li>
          ))
        : null}
    </ul>
  );
};

export default Navmenu;
