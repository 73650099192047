import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../../layouts/Header";
import Breadcrumbs from "../../layouts/Breadcrumbs";

import NeighborhoodLeaderImage from "../../../assets/img/banner/neighborhood-leader-graphic.jpeg";
import { useLocation, Link } from "react-router-dom";

function NeighborhoodLeader() {
  const location = useLocation();

  const pageTitle = "Neighborhood Leader Program | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Learn about the Democratic Party of Clackamas County Neighborhood Leader Program. Find out what we do and then sign up to become a neighborhood leader today."
        />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "Neighborhood Leader Program" }} />
      <section className="client-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 p-md-5 p-sm-0">
              <div className="col pt-3">
                <div
                  style={{
                    width: "100%",
                    marginBottom: "24px",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={NeighborhoodLeaderImage}
                      alt=""
                      style={{
                        width: window.innerWidth < 875 ? "100%" : "50%",
                      }}
                    />
                  </div>
                  <br />
                  <div className="card">
                    <div className="card-body p-3">
                      <p>
                        What is the most effective thing you can do to ensure
                        that people vote democratic? How can you help us win
                        elections?
                      </p>
                      <br />
                      <p>
                        Be a Neighborhood Leader! Reach out to your Democratic
                        neighbors and give them the information they need to
                        vote!
                      </p>
                    </div>
                  </div>
                  <br />
                  <div className="card">
                    <div className="card-header">
                      <h3>So here's how it works.</h3>
                    </div>
                    <div className="card-body p-3">
                      <ol>
                        <li>
                          You receive a list of Democratic voters in your
                          neighborhood and materials to give them.
                        </li>
                        <li>
                          You visit your neighbors and talk with them about
                          voting. Then you record your data.
                        </li>
                        <li>You follow up and make sure they vote!</li>
                      </ol>
                      <p>
                        It's easy, and it works! People who receive a visit from
                        a Neighborhood Leader are up to 20% more likely to vote!
                      </p>
                      <p>
                        We’ll train you, supply you with all the materials you
                        need and offer you support.
                      </p>
                      <p>
                        <a href="https://forms.gle/87ayMNwGaicjV3xb7">
                          Sign Up To Be a Neighborhood Leader!
                        </a>
                      </p>
                    </div>
                  </div>
                  <br />
                  <div className="card">
                    <div className="card-body">
                      <p>
                        Current neighborhood leaders,{" "}
                        <a href="https://oregonnlp.org/">
                          sign into Oregon NLP
                        </a>
                      </p>
                    </div>
                  </div>
                  <br />
                  <div className="card">
                    <div className="card-body">
                      <p>
                        On this website, you'll find the following resources
                        <ol>
                          <li>
                            <a href="/nl-training">Training</a>: Watch our short
                            online training
                          </li>
                          <li>
                            <Link to="/neighborhood-leader/training-and-resources">
                              Resources
                            </Link>
                            : Find fact sheets on the Neighborhood Leader
                            Program, sample notes for voters and other
                            canvassing resources.
                          </li>
                          <li>
                            <Link to="/neighborhood-leader/get-help">
                              Get Help
                            </Link>
                            : Contact us if you have any questions and connect
                            with us on Facebook.
                          </li>
                        </ol>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default NeighborhoodLeader;
