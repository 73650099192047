import React, { useState } from "react";
import { Link } from "react-router-dom";
import videobg from "../../../../assets/img/video-bg/pexels-hoang-le-978535 (1).jpg";
import lines from "../../../../assets/img/lines/08.png";

function NeighborhoodLeaderProgram(props) {
  return (
    <section
      className="video-section bg-img-c section-gap"
      style={{ backgroundImage: "url(" + videobg + ")" }}
    >
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-xl-7 col-lg-8 col-md-10 order-2 order-md-1">
            <div className="video-text">
              <div className="section-title left-border mb-30">
                <span className="title-tag">Neighborhood Leaders</span>
                <h2 className="title">
                  Clackamas County Neighborhood <br /> Leader Program
                </h2>
              </div>
              <div></div>
              <Link to="/neighborhood-leaders/program" className="main-btn">
                Get Information
              </Link>
            </div>
          </div>
          {/* <div className="col-lg-3 col-lg-4 col-md-2 order-1 order-md-2">
                        <div className="video-btn text-md-center wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                            <span onClick={openVideoModal} className="play-btn popup-video cursor" role="button">
                                <img src={playicon} alt="" />
                                <i className="fas fa-play" />
                            </span>
                        </div>
                    </div> */}
        </div>
      </div>
      <div className="line-shape">
        <img src={lines} alt="Line" />
      </div>
    </section>
  );
}

export default NeighborhoodLeaderProgram;
